import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ShoppingCartProvider } from "./components/_default/context/ShoppingCartContext"
import store from "./store/store"
import Redirect from "./components/_default/utilities/Redirect"
import TagManager from 'react-gtm-module'
// Pages
import Webpage67Avaleht from "./pages/Webpage67Avaleht";
import Webpage68Tooted from "./pages/Webpage68Tooted";
import Webpage69Ajurveeda from "./pages/Webpage69Ajurveeda";
import Webpage70KONTAKT from "./pages/Webpage70KONTAKT";
import Webpage71Konsultatsioon from "./pages/Webpage71Konsultatsioon";
import Webpage72Teraapiad from "./pages/Webpage72Teraapiad";
import Webpage73Toode from "./pages/Webpage73Toode";
import Webpage74Ajurveeda_soovitab from "./pages/Webpage74Ajurveeda_soovitab";
import Webpage75M____gitingimused from "./pages/Webpage75M____gitingimused";
import Webpage76Privaatsuspoliitika from "./pages/Webpage76Privaatsuspoliitika";
import Webpage77Soovitused_kevadeks from "./pages/Webpage77Soovitused_kevadeks";
import Webpage78Soovitused_s__giseks from "./pages/Webpage78Soovitused_s__giseks";
import Webpage79Soovitused_talveks from "./pages/Webpage79Soovitused_talveks";
import Webpage80Maksa from "./pages/Webpage80Maksa";
import Webpage81K__psised from "./pages/Webpage81K__psised";
import Webpage82Ajurveeda_kehat____bid from "./pages/Webpage82Ajurveeda_kehat____bid";
import Webpage83Not_found from "./pages/Webpage83Not_found";

var baseURL = 'https://d1.itprojektid.ee/webstoreApi';
var axiosHeaders = {
    Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI2IiwianRpIjoiNmU0Yzg4ZTRlZDc4ZjkzZjRmNjhjNDc0MzRkMDFjM2UxZGQzYjQ1YWY1M2I0N2Y5YWVjNTcyMzM4OGEwYjI0N2YyMGIwZjEzYjNlNWQzMTYiLCJpYXQiOjE3MjY3MzQ5MTkuMzQ1MTI0LCJuYmYiOjE3MjY3MzQ5MTkuMzQ1MTI4LCJleHAiOjE3MjY4MjEzMTkuMzQxNTk3LCJzdWIiOiI0MCIsInNjb3BlcyI6W119.Z3akdxsoAstQMCwNrYBZ9WyT8kKylxPiKzcMsTz8IWc4Es7l5GGgVfw7XoIluw_jAOHpy8QFRPJB8cBzLNPijiUnceCwsOyBfyt5m4r6bSer8Un5K-DkDh99r9gHT6bIsS1IA-rfGgMe5dzg9Yg1dGGF9lknYQatzh8O7nKj8H9JV0qke6EK5ZVmT9xyqtR-7xACfl3d-mvUA3J8D355RdKGFpUiWX1Us-3c6QQoso2Nn3SkeBZpsjjnmv5MoEMoj1OAxpVqFascAWZUNulLSqqXgIWBnGmWjo2C_hgKA9vhNQd2vylO6ShaBeobhAaYT5G7wKtGUVSMPqqo4YLXad7ZvaHUVJUDJRxlAGgoWdk9qKaBN4bRkSBM6mSYmyQKzVIg0_QqgyWf55_00VZ4x9h0SivBoU_yAoDg0e-6CKqEikkP6KlhDMJF5ZPuXnPxxMOXToU5vIAsvYc85Mq69ZIHvwEyUfv5VaIeRz_1_DCNekSDH8tUvLDQ2x4K-kqZpc_yaLZFYQiZVwQkSFtAHvI6OOvxnWl6v0AlSdU1oAHS9aIZm9MWfvDls2lPBQZQh-dN3-zd_uPVLmahtM9WXDUJ8SrEp4oLCywZZ9SnX5wOVaWxHLZHVkjFh7F91iiVsXDwMYpnQ-pebhqZoDG5c_fEAQuJ9gJgd7DINxAQXFA',
    'Access-api-key': "eyJpdiI6IlI0K1N6cHdTL3haTUQyOWpWeS96Ync9PSIsInZhbHVlIjoiOU9HY2tQdldwT3B1dDNEck9CMEtXQT09IiwibWFjIjoiNjgyNzY2YmNjNDIzODNmZWIyZmMxMjQxMmZlNzc3MGJmN2MxNTBhMTc0ZWY0ZDQ1NmI2MTc3MTViZmYzMDRlOSIsInRhZyI6IiJ9",
    'Access-api-id': "eyJpdiI6IldvWnZpdVRzN0tDWVhjYnFwZGI1V0E9PSIsInZhbHVlIjoiRlNwbXpab1BEUm5mRWZlYmRGcHZXZz09IiwibWFjIjoiNTU1ZWZjY2EwNGQ1Zjc0NDBlZWIwZWJhYWRjNmM0ZjBkNTY5NjMzNWZjYzM1MWE1NTc3NzcyMDNjYWQ2M2ZmYiIsInRhZyI6IiJ9",
    'Access-Control-Allow-Origin': "*"
 };


window.axios = require('axios').create({
  baseURL: baseURL,
  headers: axiosHeaders
});
const tagManagerArgs = {
    gtmId: "G-FQF99VNEVF"
}
TagManager.initialize(tagManagerArgs)
    
export default function App() {
    window.dataLayer.push({
        event: 'pageview'
    });
    return (
        <Provider store={store}>
            <ShoppingCartProvider>
                <BrowserRouter basename="/">
                    <Routes>
                                            <Route path="/et/" element={<Webpage67Avaleht />} exact />;
                                                                    <Route path="/en/tooted" element={<Webpage68Tooted />} exact />;
                                                                    <Route path="/en/ayurveda" element={<Webpage69Ajurveeda />} exact />;
                                                                    <Route path="/en/contact" element={<Webpage70KONTAKT />} exact />;
                                                                    <Route path="/en/consultation" element={<Webpage71Konsultatsioon />} exact />;
                                                                    <Route path="/en/therapy" element={<Webpage72Teraapiad />} exact />;
                                                                    <Route path="/en/toode/:id" element={<Webpage73Toode />} exact />;
                                                                    <Route path="/en/ayurveda/recommend" element={<Webpage74Ajurveeda_soovitab />} exact />;
                                                                    <Route path="/en/terms" element={<Webpage75M____gitingimused />} exact />;
                                                                    <Route path="/en/privacy" element={<Webpage76Privaatsuspoliitika />} exact />;
                                                                    <Route path="/en/ayurveda/seasonalSpring" element={<Webpage77Soovitused_kevadeks />} exact />;
                                                                    <Route path="/en/ayurveda/seasonalAutumn" element={<Webpage78Soovitused_s__giseks />} exact />;
                                                                    <Route path="/en/ayurveda/seasonalWinter" element={<Webpage79Soovitused_talveks />} exact />;
                                                                    <Route path="/en/checkout" element={<Webpage80Maksa />} exact />;
                                                                    <Route path="/en/cookiePolicy" element={<Webpage81K__psised />} exact />;
                                                                    <Route path="/en/ayurveda/bodytypes" element={<Webpage82Ajurveeda_kehat____bid />} exact />;
                                                                    <Route path="/en/*" element={<Webpage83Not_found />} exact />;
                                                <Route path="*" element={<Webpage83Not_found />} exact />;
                                                                
                                            <Route path="/product/:id" element={<Redirect to="/toode/:id" />} exact />;
                                            <Route path="/products" element={<Redirect to="/tooted" />} exact />;
                                                            </Routes>
                </BrowserRouter>
            </ShoppingCartProvider>
        </Provider>
    );
}